/* eslint-disable jsx-a11y/mouse-events-have-key-events */
/* eslint-disable react/prop-types */
/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import {
  Link, useSearchParams, useLocation, useNavigate,
} from 'react-router-dom';
import { FaArrowRightLong } from 'react-icons/fa6';
import { IoClose } from 'react-icons/io5';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import logo from '../../assets/images/logo-axa.svg';
import styles from './intro.module.scss';
// import useWindowSize from '../../hooks/useWindowSize';
import { SET_FILTERS } from '../../actions/types';
import { getProjectListsAction, postNodesFilteredAction } from '../../actions/projects';
import CheckIcon from '../../components/CheckIcon';
import risksItems from '../../contants/constants';
import Step1 from './animations/Step1';
import Step2 from './animations/Step2';
import Step4 from './animations/Step4';
import Step3 from './animations/Step3';
import useWindowSize from '../../hooks/useWindowSize';
import { DEFAULT_STATE } from '../../reducers/projectsReducer';

function RiskItem({ risk, selectTopic, selected }) {
  const [isHover, setIsHover] = useState(false);
  return (
    <button
      type="button"
      onClick={() => selectTopic(risk.value)}
      onMouseOver={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      className={`${styles.risk} ${selected.find((opt) => opt === risk.value) ? '' : styles.disabled}`}
      style={isHover ? { border: `2px solid ${risk.colors[1]}` } : { border: '2px solid #dbd9dd' }}
    >
      <img src={risk.icon} alt={risk.label} />
      <h3>{risk.label}</h3>
    </button>
  );
}

function TitleAnime({ children }) {
  return (
    <motion.h2
      initial={{
        x: -60,
        opacity: 0,
      }}
      transition={{ delay: 0.2 }}
      animate={{
        x: 0,
        opacity: 1,
      }}
    >
      {children}
    </motion.h2>
  );
}

export default function Intro() {
  const location = useLocation();
  const navigate = useNavigate();
  const { data, filters, lists } = useSelector((state) => state.projectsReducer);
  const { width, height } = useWindowSize();
  const [step, setStep] = useState(1);
  const [search, setSearch] = useState('');
  const time = 6000;

  const [selected, setSelected] = useState(filters.filters);
  const { reset } = useForm();
  const [searchParams, setSearchParams] = useSearchParams();

  const optionsFound = search && data?.options?.filter(
    (opt) => opt.label.toLowerCase().includes(search.toLowerCase()),
  );

  const foundCategories = optionsFound?.length > 0 && [
    {
      label: 'Risks',
      options: optionsFound.filter((opt) => opt.group === 'risk'),
    },
    {
      label: 'Topics',
      options: optionsFound.filter((opt) => opt.group === 'topic'),
    },
    {
      label: 'Countries',
      options: optionsFound.filter((opt) => opt.group === 'country'),
    },
    {
      label: 'Institutions',
      options: optionsFound.filter((opt) => opt.group === 'institution'),
    },
  ];

  const dispatch = useDispatch();

  async function getSearchParams() {
    const urlParams = new URLSearchParams(searchParams);
    const entries = urlParams.entries();
    const result = {
      ...filters,
    };

    // eslint-disable-next-line no-restricted-syntax
    for (const [key, value] of entries) {
      if (key === 'countries' && value.split(',').length >= 1) {
        result.filters = [...result.filters, ...value.split(',')];
        result.countries = value.split(',');
      }
      if (key === 'institutions' && value.split(',').length >= 1) {
        result.filters = [...result.filters, ...value.split(',')];
        result.institutions = value.split(',');
      }
      if (key === 'topics' && value.split(',').length >= 1) {
        result.filters = [...result.filters, ...value.split(',')];
        result.topics = value.split(',');
      }
      if (key === 'risks' && value.split(',').length >= 1) {
        result.filters = [...result.filters, ...value.split(',')];
        result.risks = value.split(',');
      }
      if (value === 'false') {
        result[key] = false;
      }
      if (value === 'true') {
        result[key] = true;
      }
    }

    dispatch({
      type: SET_FILTERS,
      payload: result,
    });
    reset(result);
    await postNodesFilteredAction(dispatch, result);
  }

  function handleChangeFilters(obj) {
    const updatedFilters = {
      ...filters,
      ...obj,
    };
    setSearchParams(updatedFilters);
    dispatch({
      type: SET_FILTERS,
      payload: updatedFilters,
    });
  }

  function handleChangeSearch(value, type = null) {
    let selectedList = [...selected];
    const found = selected.find((s) => s === value);
    if (found) {
      selectedList = selectedList.filter((s) => s !== value);
    } else {
      selectedList.push(value);
    }
    let countries = data.options?.filter((opt) => selectedList.find((o) => o === opt.value) && opt.group === 'country').map((o) => o.value);
    let institutions = data.options?.filter((opt) => selectedList.find((o) => o === opt.value) && opt.group === 'institution').map((o) => o.value);
    let topics = data.options?.filter((opt) => selectedList.find((o) => o === opt.value) && opt.group === 'topic').map((o) => o.value);
    let risks = data.options?.filter((opt) => selectedList.find((o) => o === opt.value) && opt.group === 'risk').map((o) => o.value);
    if (type === 'risk') {
      selectedList = [value];
      countries = [];
      institutions = [];
      topics = [];
      risks = [value];
    }
    setSelected(selectedList);
    setSearch('');
    setSearchParams({
      countries,
      institutions,
      topics,
      risks,
    });
  }

  async function selectTopic(value) {
    await handleChangeSearch(value, 'risk');
    navigate(`/projets${location.search}`);
  }

  useEffect(() => {
    handleChangeFilters({ ...DEFAULT_STATE.filters });
    getProjectListsAction(dispatch);
  }, []);

  useEffect(() => {
    if (searchParams) {
      getSearchParams();
    }
  }, [searchParams]);

  useEffect(() => {
    if (lists?.dates?.length > 0) {
      const { startDate } = lists.dates[lists.dates.length - 1];
      const { endDate } = lists.dates[0];
      handleChangeFilters({
        endDate,
        startDate,
      });
    }
  }, [lists]);

  useEffect(() => {
    let timer = null;
    if (step < 5) {
      timer = setTimeout(() => {
        setStep((s) => s + 1);
        clearTimeout(timer);
      }, time);
    }
    return () => {
      clearTimeout(timer);
    };
  }, [step]);

  return (
    <div className={styles.container}>
      <div className={styles.logo}>
        <img src={logo} alt="logo-axa" />
      </div>
      {width && height
      && (
      <>
        {step < 5
          ? (
            <button
              type="button"
              className={styles.skip}
              onClick={() => setStep(5)}
            >
              SKIP INTRO
              <FaArrowRightLong />
            </button>
          )
          : (
            <Link className={styles.seeAll} to={`/projets${location.search}`}>
              See all data
            </Link>
          )}
        <div className={styles.title}>
          {step >= 1
          && (
          <TitleAnime>
            Welcome to Axa data
          </TitleAnime>
          )}
          {step === 1 && (
          <TitleAnime>
            Millions of euros
            <br />
            engaged
          </TitleAnime>
          )}
          {step === 2 && (
          <TitleAnime>
            708 scientific
            <br />
            research projects
          </TitleAnime>
          )}
          {step === 3 && (
          <TitleAnime>
            Supported in 39
            <br />
            different countries
          </TitleAnime>
          )}
          {step === 4 && (
          <TitleAnime>
            With 334 academic
            <br />
            institutions
          </TitleAnime>
          )}
          {step === 5 && (
          <div className={styles.search}>
            <input
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              placeholder={selected.length > 0 ? 'Do you want to add another tag ?' : 'What do you want to see ?'}
            />
            <div className={styles.options}>
              {foundCategories && foundCategories?.filter((o) => o.options.length)?.map((opt) => (
                <div className={styles.option}>
                  <span>{opt.label}</span>
                  <div>
                    {opt.options.map((o) => (
                      <button
                        key={o.label}
                        type="button"
                        onClick={() => handleChangeSearch(o.value)}
                      >
                        <CheckIcon checked={selected.find((s) => s === o.value)} />
                        {o.label}
                      </button>
                    ))}
                  </div>
                </div>
              ))}
            </div>
            <div className={styles.results}>
              <div className={styles.selected}>
                {data.options.filter(
                  (opt) => selected.find((s) => s === opt.value),
                ).map((opt) => (
                  <button
                    type="button"
                    key={opt.value}
                    onClick={() => handleChangeSearch(opt.value)}
                  >
                    {opt.label}
                    <IoClose />
                  </button>
                ))}
              </div>
              <Link to={`/projets${location.search}`}>

                Access the results
                <FaArrowRightLong />
              </Link>
            </div>
          </div>
          )}
        </div>
        {step === 5
      && (
      <div className={styles.risks}>
        <p>CHOOSE YOUR THEMATIC</p>
        <div className={styles.list}>
          {risksItems.map((r) => (
            <RiskItem
              key={r.value}
              risk={r}
              selectTopic={(v) => selectTopic(v)}
              selected={selected}
            />
          ))}
        </div>
      </div>
      )}
        {step === 1 && <Step1 width={width} height={height} />}
        {step === 2 && <Step2 width={width} height={height} />}
        {step === 3 && <Step3 width={width} height={height} />}
        {step === 4 && <Step4 width={width} height={height} />}
      </>
      )}
    </div>
  );
}
