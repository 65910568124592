/* eslint-disable react/prop-types */
/* eslint-disable react/no-array-index-key */
import React from 'react';
import { motion, MotionConfig } from 'framer-motion';
import styles from './anime.module.scss';

export default function Step2({ width, height }) {
  const defaultCicle = {
    opacity: 1,
    top: 0,
    left: 0,
    backgroundColor: '#00005C',
    width: 0,
    height: 0,
    borderRadius: 10,
    position: 'absolute',
    x: (width / 4) * 3,
    y: (height / 2),
  };
  const circles = [
    [
      {
        ...defaultCicle,
        x: defaultCicle.x - (457 / 2),
        y: defaultCicle.y - (457 / 2),
        width: 457,
        height: 457,
        backgroundColor: '#FFFFFF',
        borderRadius: 457,
        opacity: 0,
      },
      {
        ...defaultCicle,
        x: defaultCicle.x - (457 / 2),
        y: defaultCicle.y - (457 / 2),
        width: 457,
        height: 457,
        backgroundColor: '#FFFFFF',
        borderRadius: 457,
        opacity: 1,
      },
    ],
    [
      {
        ...defaultCicle,
        x: defaultCicle.x + 10,
        y: defaultCicle.y + 168,
        zIndex: 10,
        width: 42,
        height: 42,
        opacity: 0,
      },
      {
        ...defaultCicle,
        x: defaultCicle.x + 10,
        y: defaultCicle.y + 168,
        zIndex: 10,
        width: 42,
        height: 42,
        backgroundColor: '#5A9277',
        opacity: 1,
      },
    ],
    [
      {
        ...defaultCicle,
        x: defaultCicle.x - 10,
        y: defaultCicle.y + 110,
        zIndex: 10,
        width: 42,
        height: 42,
        opacity: 0,
      },
      {
        ...defaultCicle,
        x: defaultCicle.x - 10,
        y: defaultCicle.y + 110,
        zIndex: 10,
        width: 42,
        height: 42,
        backgroundColor: '#5A9277',
        opacity: 1,
      },
    ],
    [
      {
        ...defaultCicle,
        x: defaultCicle.x - 115,
        y: defaultCicle.y + 98,
        zIndex: 10,
        width: 85,
        height: 85,
        opacity: 0,
      },
      {
        ...defaultCicle,
        x: defaultCicle.x - 115,
        y: defaultCicle.y + 98,
        zIndex: 10,
        width: 85,
        height: 85,
        backgroundColor: '#5A9277',
        opacity: 1,
      },
    ],
    [
      {
        ...defaultCicle,
        x: defaultCicle.x + 55,
        y: defaultCicle.y + 68,
        zIndex: 10,
        width: 85,
        height: 85,
        opacity: 0,
      },
      {
        ...defaultCicle,
        x: defaultCicle.x + 55,
        y: defaultCicle.y + 68,
        zIndex: 10,
        width: 85,
        height: 85,
        backgroundColor: '#9090AC',
        opacity: 1,
      },
    ],
    [
      {
        ...defaultCicle,
        x: defaultCicle.x + 155,
        y: defaultCicle.y + 28,
        zIndex: 10,
        width: 42,
        height: 42,
        opacity: 0,
      },
      {
        ...defaultCicle,
        x: defaultCicle.x + 155,
        y: defaultCicle.y + 28,
        zIndex: 10,
        width: 42,
        height: 42,
        backgroundColor: '#5A9277',
        opacity: 1,
      },
    ],
    [
      {
        ...defaultCicle,
        x: defaultCicle.x + 58,
        y: defaultCicle.y - 68,
        zIndex: 10,
        width: 85,
        height: 85,
        opacity: 0,
      },
      {
        ...defaultCicle,
        x: defaultCicle.x + 58,
        y: defaultCicle.y - 68,
        zIndex: 10,
        width: 85,
        height: 85,
        backgroundColor: '#00ADC6',
        opacity: 1,
      },
    ],
    [
      {
        ...defaultCicle,
        x: defaultCicle.x + -58,
        y: defaultCicle.y - 30,
        zIndex: 10,
        width: 85,
        height: 85,
        opacity: 0,
      },
      {
        ...defaultCicle,
        x: defaultCicle.x + -58,
        y: defaultCicle.y - 30,
        zIndex: 10,
        width: 85,
        height: 85,
        backgroundColor: '#DDBE64',
        opacity: 1,
      },
    ],
    [
      {
        ...defaultCicle,
        x: defaultCicle.x - 168,
        y: defaultCicle.y - 70,
        zIndex: 10,
        width: 85,
        height: 85,
        opacity: 0,
      },
      {
        ...defaultCicle,
        x: defaultCicle.x - 168,
        y: defaultCicle.y - 70,
        zIndex: 10,
        width: 85,
        height: 85,
        backgroundColor: '#5A9277',
        opacity: 1,
      },
    ],
    [
      {
        ...defaultCicle,
        x: defaultCicle.x - 180,
        y: defaultCicle.y + 40,
        zIndex: 10,
        width: 42,
        height: 42,
        opacity: 0,
      },
      {
        ...defaultCicle,
        x: defaultCicle.x - 180,
        y: defaultCicle.y + 40,
        zIndex: 10,
        width: 42,
        height: 42,
        backgroundColor: '#9090AC',
        opacity: 1,
      },
    ],
    [
      {
        ...defaultCicle,
        x: defaultCicle.x - 180,
        y: defaultCicle.y + 40,
        zIndex: 10,
        width: 42,
        height: 42,
        opacity: 0,
      },
      {
        ...defaultCicle,
        x: defaultCicle.x - 180,
        y: defaultCicle.y + 40,
        zIndex: 10,
        width: 42,
        height: 42,
        backgroundColor: '#9090AC',
        opacity: 1,
      },
    ],
    [
      {
        ...defaultCicle,
        x: defaultCicle.x - 58,
        y: defaultCicle.y - 160,
        zIndex: 10,
        width: 85,
        height: 85,
        opacity: 0,
      },
      {
        ...defaultCicle,
        x: defaultCicle.x - 58,
        y: defaultCicle.y - 160,
        zIndex: 10,
        width: 85,
        height: 85,
        backgroundColor: '#5A9277',
        opacity: 1,
      },
    ],
    [
      {
        ...defaultCicle,
        x: defaultCicle.x - 140,
        y: defaultCicle.y - 170,
        zIndex: 10,
        width: 42,
        height: 42,
        opacity: 0,
      },
      {
        ...defaultCicle,
        x: defaultCicle.x - 140,
        y: defaultCicle.y - 170,
        zIndex: 10,
        width: 42,
        height: 42,
        backgroundColor: '#DDBE64',
        opacity: 1,
      },
    ],
    [
      {
        ...defaultCicle,
        x: defaultCicle.x + 50,
        y: defaultCicle.y - 180,
        zIndex: 10,
        width: 42,
        height: 42,
        opacity: 0,
      },
      {
        ...defaultCicle,
        x: defaultCicle.x + 50,
        y: defaultCicle.y - 180,
        zIndex: 10,
        width: 42,
        height: 42,
        backgroundColor: '#DDBE64',
        opacity: 1,
      },
    ],
  ];

  return (
    <MotionConfig transition={{ duration: 0.8 }}>
      {circles.map((c, i) => (
        <motion.div
          key={`circle-${i}`}
          className={styles.element}
          initial={{
            ...c[0],
            transform: `translate(${c[0].x}px,${c[0].y}px)`,
          }}
          transition={{ delay: i * 0.2 }}
          animate={{
            ...c[1],
            transform: `translate(${c[1].x}px,${c[1].y}px)`,
          }}
        >
          {c[1]?.text?.number && (
            <h3>
              {c[1]?.text?.number}
              <br />
              {c[1]?.text?.label}
              <br />
            </h3>
          )}
        </motion.div>
      ))}
    </MotionConfig>
  );
}
