import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Intro from './pages/intro';
import Visualization from './pages/visualization';
import store from './reducers';

function App() {
  return (
    <div className="App">
      <Provider store={store}>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Intro />} />
            <Route path="/projets" element={<Visualization />} />
          </Routes>
        </BrowserRouter>
      </Provider>
    </div>
  );
}

export default App;
