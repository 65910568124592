/* eslint-disable react/prop-types */
/* eslint-disable react/no-array-index-key */
import React from 'react';
import { motion, MotionConfig } from 'framer-motion';
import styles from './anime.module.scss';

export default function Step1({ width, height }) {
  const defaultCicle = {
    opacity: 1,
    top: 0,
    left: 0,
    backgroundColor: '#00005C',
    width: 0,
    height: 0,
    borderRadius: 10,
    position: 'absolute',
    x: width ? (width / 4) * 3 : 0,
    y: height ? (height / 2) : 0,
  };
  const circles = [
    [
      {
        ...defaultCicle,
        x: defaultCicle.x,
        y: defaultCicle.y - 248,
        width: 38,
        height: 38,
        borderRadius: 358,
        opacity: 0,
      },
      {
        ...defaultCicle,
        x: defaultCicle.x,
        y: defaultCicle.y - 248,
        width: 38,
        height: 38,
        borderRadius: 358,
        opacity: 1,
      },
    ],
    [
      {
        ...defaultCicle,
        x: defaultCicle.x + 61,
        y: defaultCicle.y - 240,
        width: 81,
        height: 81,
        borderRadius: 358,
        opacity: 0,
      },
      {
        ...defaultCicle,
        x: defaultCicle.x + 61,
        y: defaultCicle.y - 240,
        width: 81,
        height: 81,
        borderRadius: 358,
        opacity: 1,
      },
    ],
    [
      {
        ...defaultCicle,
        x: defaultCicle.x,
        y: defaultCicle.y,
        borderRadius: 358,
        opacity: 0,
        width: 0,
        height: 0,
      },
      {
        ...defaultCicle,
        x: defaultCicle.x - (358 / 2),
        y: defaultCicle.y - (358 / 2),
        width: 358,
        height: 358,
        text: {
          number: 250,
          label: 'millions',
        },
        borderRadius: 358,
        opacity: 1,
      },
    ],
  ];

  return (
    <MotionConfig transition={{ duration: 0.8 }}>
      {circles.map((c, i) => (
        <motion.div
          key={`circle-${i}`}
          className={styles.element}
          initial={{
            ...c[0],
            transform: `translate(${c[0].x}px,${c[0].y}px)`,
          }}
          transition={{ delay: i * 0.2 }}
          animate={{
            ...c[1],
            transform: `translate(${c[1].x}px,${c[1].y}px)`,
          }}
        >
          {c[1]?.text?.number && (
            <h3>
              {c[1]?.text?.number}
              <br />
              {c[1]?.text?.label}
              <br />
            </h3>
          )}
        </motion.div>
      ))}
    </MotionConfig>
  );
}
