import React, { useEffect, useLayoutEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { BiSolidLeftArrow, BiSolidRightArrow } from 'react-icons/bi';
// import arrow from '../../assets/images/icons/arrow.svg';
import country from '../../assets/images/icons/country.svg';
import topic from '../../assets/images/icons/topic.svg';
import institution from '../../assets/images/icons/institution.svg';
// import project from '../../assets/images/icons/project.svg';

import styles from './Top10.module.scss';

export default function Top10() {
  const { data, filters } = useSelector((state) => state.projectsReducer);
  const [top10, setTop10] = useState([]);
  const [index, setIndex] = useState(0);
  const [height, setHeight] = useState(0);
  const selected = top10?.find((t, i) => i === index);

  function handleIndex(value) {
    let i = value;
    if (value > top10.length - 1) i = top10.length - 1;
    if (value < 0) i = 0;
    setIndex(i);
  }

  useEffect(() => {
    if (data?.nodes) {
      const top = [
      ];
      if (filters?.isCountries) {
        top.push({
          label: 'Countries',
          icon: country,
          top: data.nodes.filter((n) => n.type === 'country').sort((a, b) => (a.size > b.size ? -1 : 1)).filter((n, i) => i < 10),
        });
      }
      if (filters?.isTopics) {
        top.push({
          label: 'Tags',
          icon: topic,
          top: data.nodes.filter((n) => n.type === 'topic').sort((a, b) => (a.size > b.size ? -1 : 1)).filter((n, i) => i < 10),
        });
      }
      if (filters?.isInstitutions) {
        top.push({
          label: 'Institutions',
          icon: institution,
          top: data.nodes.filter((n) => n.type === 'institution').sort((a, b) => (a.size > b.size ? -1 : 1)).filter((n, i) => i < 10),
        });
      }
      if (top.length < top10?.length) {
        setIndex(0);
      }

      setTop10(top);
    }
  }, [data, filters]);

  useLayoutEffect(() => {
    const element = document.getElementById(`top-${index}`);
    const clientHeight = element?.clientHeight > 0 ? element.clientHeight + 8 : 0;
    setHeight(clientHeight);
  }, [index, top10]);

  return (
    <div className={`${styles.container} ${top10?.length === 0 && styles['d-none']}`}>
      <div className={styles.title}>
        <div className={styles.label}>
          <h2>Top 10</h2>
          {selected?.label
            && (
            <>
              <img src={selected.icon} alt={selected.name} />
              <h2>{selected.label}</h2>
            </>
            )}
        </div>
        {top10?.length >= 2
          && (
          <div className={styles.toggles}>
            <button
              type="button"
              className={index === 0 ? styles.disabled : ''}
              onClick={() => handleIndex(index - 1)}
            >
              <BiSolidLeftArrow />
            </button>
            <button
              type="button"
              className={index === top10.length - 1 ? styles.disabled : ''}
              onClick={() => handleIndex(index + 1)}
            >
              <BiSolidRightArrow />
            </button>

          </div>
          )}
      </div>
      <div className={styles.content}>
        <div className={styles.tops} style={{ height, transform: `translateX(-${index * 260}px)` }}>
          {top10.map((t, i) => (
            <div key={t.label} className={styles.top} id={`top-${i}`}>
              {t.top.map((v) => (
                <div key={v.name} className={styles.item}>
                  <p>{v.size}</p>
                  {' '}
                  <p>{v.name}</p>
                </div>
              ))}

            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
