import axios from 'axios';

export const API_URL = process.env.REACT_APP_API_URL;

export function errorHandler(dispatch, error, type) {
  console.log('Error type: ', type);
  if (type) {
    dispatch({
      type,
      payload: error.response,
    });
  }
}

export async function postData(errorType, url, dispatch, data) {
  const requestUrl = API_URL + url;
  const config = {};
  let res = null;
  let error = null;
  try {
    res = await axios.post(requestUrl, data, config);
  } catch (err) {
    error = err;
    errorHandler(dispatch, err, errorType);
  }
  return res?.status === 200 || res?.status === 201 ? res : error;
}

export async function getData(errorType, url, dispatch, isAuthReq) {
  const requestUrl = API_URL + url;
  const config = {};
  let res = null;
  let error = null;
  if (isAuthReq) {
    const token = localStorage.getItem('token');
    config.headers = { Authorization: `${token}` };
  }

  try {
    res = await axios.get(requestUrl, config);
  } catch (err) {
    error = err;
    errorHandler(dispatch, err, errorType);
  }
  return res?.status === 200 || res?.status === 201 ? res : error;
}
