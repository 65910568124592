/* eslint-disable react/prop-types */
/* eslint-disable react/no-array-index-key */
import React from 'react';
import { motion, MotionConfig } from 'framer-motion';
import styles from './anime.module.scss';
import world from '../../../assets/images/world.svg';

export default function Step3({ width, height }) {
  const defaultCicle = {
    top: 0,
    left: 0,
    transformOrigin: 'center',
    position: 'absolute',
    x: ((width / 4) * 3) - (615 / 1.8),
    y: (height / 2) - (307 / 1.6),
  };

  return (
    <MotionConfig transition={{ duration: 0.8 }}>
      <motion.div
        className={styles.element}
        initial={{
          ...defaultCicle,
          opacity: 0,
          transform: `translate(${defaultCicle.x}px,${defaultCicle.y}px)`,
        }}
        transition={{ delay: 0.2 }}
        animate={{
          ...defaultCicle,
          opacity: 1,
          transform: `translate(${defaultCicle.x}px,${defaultCicle.y}px)`,
        }}
      >
        <img src={world} alt="world" />
      </motion.div>

    </MotionConfig>
  );
}
