/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import * as d3 from 'd3';
import { useDispatch, useSelector } from 'react-redux';

import { useNavigate, useSearchParams } from 'react-router-dom';
import logo from '../../assets/images/logo-axa.svg';
import imgDataDark from './images/data-dark.svg';
import imgData from './images/data.svg';
import imgGroupDark from './images/group-dark.svg';
import imgGroup from './images/group.svg';
import imgPinDark from './images/pin-dark.svg';
import imgPin from './images/pin.svg';

import { getProjectListsAction, postNodesFilteredAction } from '../../actions/projects';
import styles from './Filters.module.scss';
import { SET_FILTERS } from '../../actions/types';
import DropDown from '../DropDown';
import Timeline from '../Timeline';
import Top10 from '../Top10';
import { DEFAULT_STATE } from '../../reducers/projectsReducer';

export function Stat({ stat, statistics = [], isCountry = false }) {
  const extent = d3.extent(statistics.map(((n) => n.count)));
  const scale = d3.scaleLinear()
    .domain(extent)
    .range([0, 100]);
  return (
    <div className={`${styles.stat} ${isCountry ? styles.country : ''}`}>
      <p className={styles.label}>{stat.label}</p>
      <div className={styles['bar-container']}>
        <div
          className={styles.bar}
          style={{
            height: 15,
            width: `${scale(stat.count)}%`,
            border: `1px solid ${stat.colors[0]}`,
            backgroundColor: stat.colors[1],
            borderRadius: 3,
          }}
        />
        <p className={styles.count}>{stat.count}</p>
      </div>
    </div>
  );
}

export default function Filters() {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const { data, filters, lists } = useSelector((state) => state.projectsReducer);
  const options = lists?.options || [];

  const statistics = data?.options?.filter((opt) => opt.group === 'risk').sort((a, b) => b.count - a.count);

  async function getSearchParams() {
    const urlParams = new URLSearchParams(searchParams);
    const entries = urlParams.entries();
    const result = {
      ...filters,
      filters: [],
    };
    // eslint-disable-next-line no-restricted-syntax
    for (const [key, value] of entries) {
      if (key === 'view' || key === 'startDate' || key === 'endDate') {
        result[key] = value;
      }
      if (key === 'countries') {
        result.filters = [...result.filters, ...value];
        if (!result.countries.find((c) => c === value)) {
          result.countries = [...result.countries, value];
        }
      }
      if (key === 'institutions') {
        result.filters = [...result.filters, ...value];
        if (!result.institutions.find((c) => c === value)) {
          result.institutions = [...result.institutions, value];
        }
      }
      if (key === 'topics') {
        result.filters = [...result.filters, ...value];
        if (!result.topics.find((c) => c === value)) {
          result.topics = [...result.topics, value];
        }
      }
      if (key === 'risks') {
        result.filters = [...result.filters, ...value];
        if (!result.risks.find((c) => c === value)) {
          result.risks = [...result.risks, value];
        }
      }
      if (value === 'false') {
        result[key] = false;
      }
      if (value === 'true') {
        result[key] = true;
      }
    }

    dispatch({
      type: SET_FILTERS,
      payload: result,
    });
  }

  async function gotToIntro() {
    await dispatch({
      type: SET_FILTERS,
      payload: { ...DEFAULT_STATE.filters },
    });
    await navigate('/');
  }

  function handleChangeFilters(obj) {
    const updatedFilters = {
      ...filters,
      ...obj,
    };
    setSearchParams(updatedFilters);
    dispatch({
      type: SET_FILTERS,
      payload: updatedFilters,
    });
  }

  useEffect(() => {
    if (searchParams) {
      getSearchParams();
    }
  }, []);

  useEffect(() => {
    getProjectListsAction(dispatch);
  }, []);

  useEffect(() => {
    if (!filters.startDate && lists?.dates?.length > 0) {
      const { startDate } = lists.dates[lists.dates.length - 1];
      const { endDate } = lists.dates[0];
      handleChangeFilters({
        endDate,
        startDate,
      });
    }
  }, [lists.dates, filters.startDate]);

  useEffect(() => {
    if (filters.startDate) {
      postNodesFilteredAction(dispatch, filters);
    }
  }, [filters]);

  return (
    <div className={styles.container}>
      <button
        type="button"
        onClick={() => gotToIntro()}
        className={styles.logo}
      >
        <img src={logo} alt="logo-axa" />
      </button>

      <div className={styles['filters-container']}>
        <div className={styles.content}>
          <div
            className={styles.map}
          >
            <button
              type="button"
              className={filters?.view === 'groups' ? styles.active : ''}
              onClick={() => handleChangeFilters({
                ...filters,
                view: 'groups',
              })}
            >
              <img src={filters?.view === 'groups' ? imgGroup : imgGroupDark} alt="toggle-map" />
            </button>
            <button
              type="button"
              className={filters?.view === 'links' ? styles.active : ''}
              onClick={() => handleChangeFilters({
                ...filters,
                view: 'links',
              })}
            >
              <img src={filters?.view === 'links' ? imgData : imgDataDark} alt="toggle-map" />
            </button>
            <button
              type="button"
              className={filters?.view === 'map' ? styles.active : ''}
              onClick={() => handleChangeFilters({
                ...filters,
                view: 'map',
              })}
            >
              <img src={filters?.view === 'map' ? imgPin : imgPinDark} alt="toggle-map" />
            </button>

          </div>
          <div className={styles.filters}>
            <div className={styles.col}>
              <DropDown
                label="Countries"
                isActive={filters.isCountries}
                setIsActive={(value) => handleChangeFilters({
                  isCountries: value,
                })}
                values={filters.countries || []}
                handleChange={(values) => handleChangeFilters({
                  countries: values,
                })}
                options={options?.filter((opt) => opt.group === 'country') || []}
              />
            </div>
            <div className={styles.col}>
              <DropDown
                label="Institutions"
                isActive={filters.isInstitutions}
                setIsActive={(value) => handleChangeFilters({
                  isInstitutions: value,
                })}
                values={filters.institutions || []}
                handleChange={(values) => handleChangeFilters({
                  institutions: values,
                })}
                options={options?.filter((opt) => opt.group === 'institution') || []}
              />
            </div>
            <div className={styles.col}>
              <DropDown
                label="Tags"
                isActive={filters.isTopics}
                setIsActive={(value) => handleChangeFilters({
                  isTopics: value,
                })}
                values={filters.topics || []}
                handleChange={(values) => handleChangeFilters({
                  topics: values,
                })}
                options={options?.filter((opt) => opt.group === 'topic') || []}
              />
            </div>
            <div className={styles.col}>
              <DropDown
                label="Risks"
                isActive
                setIsActive={(value) => handleChangeFilters({
                  isRisks: value,
                })}
                values={filters.risks || []}
                handleChange={(values) => handleChangeFilters({
                  risks: values,
                })}
                options={options?.filter((opt) => opt.group === 'risk') || []}
              />
            </div>
            <div className={styles.col}>
              <DropDown
                label="Projects"
                isActive={filters.isProjects}
                setIsActive={(value) => handleChangeFilters({
                  isProjects: value,
                })}
              />
            </div>
          </div>
        </div>
      </div>
      {filters.startDate && filters.endDate && lists?.dates?.length > 0
        && (
          <Timeline
            options={lists.dates}
            handleChange={(obj) => handleChangeFilters(obj)}
          />
        )}
      <div className={styles.aside}>
        <div className={styles.risks}>
          <h2>Thematics</h2>
          {statistics?.filter((opt) => opt.group === 'risk')
            .map((stat) => (
              <Stat
                key={stat.label}
                stat={stat}
                statistics={statistics}
              />
            ))}
        </div>
        <Top10 />
      </div>
    </div>
  );
}
