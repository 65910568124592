/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import useOutsideClick from '../../hooks/useClickOutside';
import useWindowSize from '../../hooks/useWindowSize';
import { Stat } from '../Filters';
import styles from './CountryInfos.module.scss';

function CountryInfos({ country, close }) {
  const { width, height } = useWindowSize();

  const [prevPos, setPrevPos] = useState({
    x: width / 2,
    y: height / 2,
  });
  function handleClose(e) {
    if (e.target.className.baseVal !== 'country') {
      close();
    }
  }

  useEffect(() => {
    if (country?.pos) {
      setPrevPos({
        x: country.pos.x + 25,
        y: country.pos.y - 100,
      });
    }
  }, [country]);

  const countryRef = useOutsideClick(handleClose);
  return (
    <div
      ref={countryRef}
      className={styles.container}
      style={{
        opacity: country ? 1 : 0,
        top: prevPos?.y || 0,
        left: prevPos?.x || 0,
      }}
    >
      <div className={styles.content}>
        <h2>{country?.name}</h2>
        <div className={styles.row}>
          <div className={styles.col}>
            {country?.risks.sort((a, b) => (a.count > b.count ? -1 : 1))?.map((r) => (
              <Stat key={`country-${r?.value}`} stat={r} statistics={country?.risks} isCountry />
            ))}
          </div>
          <div className={styles.col}>
            <p>
              {country?.topics?.length}
              {' '}
              Tags
            </p>
            <p>
              {country?.institutions?.length}
              {' '}
              Institutions
            </p>
            <p>
              {country?.projects}
              {' '}
              Projets
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CountryInfos;
