import { getData, postData } from '.';
import {
  GET_LISTS, POST_NODES_FILTERED, PROJECT_ERROR, PROJECT_IS_LOADING,
} from './types';

export const postNodesFilteredAction = async (dispatch, data) => {
  const url = '/project/filtered';
  dispatch({
    type: PROJECT_IS_LOADING,
    payload: POST_NODES_FILTERED,
  });
  const response = await postData(PROJECT_ERROR, url, dispatch, data);
  if (response.data) {
    dispatch({
      type: POST_NODES_FILTERED,
      payload: response.data,
    });
  }
  return response;
};

export const getProjectListsAction = async (dispatch) => {
  const url = '/project/lists';
  dispatch({
    type: PROJECT_IS_LOADING,
    payload: GET_LISTS,
  });
  const response = await getData(PROJECT_ERROR, url, dispatch, false);
  if (response.data) {
    dispatch({
      type: GET_LISTS,
      payload: response.data,
    });
  }
  return response;
};
