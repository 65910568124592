import React from 'react';
import Filters from '../../components/Filters';
import ForceGraph from '../../components/ForceGraph';
import styles from './visualization.module.scss';

export default function Visualization() {
  return (
    <div className={styles.container}>
      <Filters />
      <ForceGraph />
    </div>
  );
}
