/* eslint-disable new-cap */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-param-reassign */
/* eslint-disable no-inner-declarations */
import React, { useEffect, useRef, useState } from 'react';
// import { jsPDF } from 'jspdf';
// import html2canvas from 'html2canvas';
import * as d3 from 'd3';
import { useSelector } from 'react-redux';
import { Oval } from 'react-loader-spinner';
import geojson from './mapData/world.json';
// import capitals from './mapData/capitals.json';
import styles from './ForceGraph.module.scss';
import useWindowSize from '../../hooks/useWindowSize';
import ProjectInfos from '../ProjectInfos/ProjectInfos';
import { POST_NODES_FILTERED } from '../../actions/types';
import hexToRGBA from '../../utils';
import risks from '../../contants/constants';
import CountryInfos from '../CountryInfos';

const primary = '#00005C';
const white = '#FFFFFF';

export default function ForceGraph() {
  const { width, height } = useWindowSize();
  const { data, filters, isLoading } = useSelector((state) => state.projectsReducer);
  const [selectedProject, setSelectedProject] = useState();
  const [selectedCountry, setSelectedCountry] = useState();
  const [mapTransform, setMapTransform] = useState(null);
  const [renderIsLoading, setRenderIsLoading] = useState(true);
  const [prevNodes, setPrevNodes] = useState([]);
  const svgRef = useRef(null);
  const svg = d3.select(svgRef.current);
  const dataIsLoading = isLoading?.find((l) => l === POST_NODES_FILTERED);

  // async function exportD3ToPDF() {
  //   // const svgGraph = document.getElementById('svg');
  //   html2canvas(document.body).then((canvas) => {
  //     console.log(canvas);
  //     const pdf = new jsPDF({
  //       orientation: 'landscape',
  //     });
  //     const imgData = canvas.toDataURL('image/png', 1.0);
  //     const imgProps = pdf.getImageProperties(imgData);
  //     const pdfWidth = pdf.internal.pageSize.getWidth();
  //     const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

  //     pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
  //     pdf.save('visualization.pdf');
  //   });
  // }

  useEffect(() => {
    if (dataIsLoading) {
      setRenderIsLoading(true);
    }
  }, [dataIsLoading]);
  // re-create animation every time nodes change
  useEffect(() => {
    if (data?.nodes && width > 0) {
      if (svgRef.current) {
        svg.selectAll('g').remove();
      }
      const geojsonData = { ...geojson, features: geojson.features.filter((f) => f.properties.name_en !== 'Antarctica') };
      const g = svg.append('g')
        .attr('id', 'group');
      console.log(filters.view);
      const links = filters.view === 'links' ? [...data.links] : [];
      const nodes = [...data.nodes.sort((a, b) => (b.size > a.size ? 1 : -1))];

      const center = d3.geoCentroid(geojsonData);
      const offset = [(width / 2) + 70, (height / 2 - 50)];
      const mapScale = 150;
      let countryMap = null;

      if (filters.view === 'map') {
        const projection = d3.geoMercator()
          .scale(mapScale)
          .center(center)
          .translate(offset);

        const map = g.append('g')
          .attr('id', 'map');

        const countriesSize = nodes.filter((n) => n.type === 'country').map((n) => n.size);
        const max = Math.max(...countriesSize);

        const countryOpacity = d3.scaleLinear()
          .domain([1, max])
          .range([0.2, 1]);

        let color = '#02005C';
        if (filters.risks.length === 1) {
          const found = risks?.find((r) => r.value === filters.risks[0]);
          if (found) {
            color = found.colors[1];
          }
        }
        geojsonData?.features.forEach((feature, i) => {
          geojsonData.features[i].color = null;
          const foundIndex = nodes?.find(
            (n) => feature?.properties?.iso_a3 === n.code,
          );
          if (foundIndex) {
            geojsonData.features[i].color = color;
            geojsonData.features[i].size = foundIndex.size;
            const countryProjects = data?.nodes.filter((n) => (n.type === 'project' && n.country.code === foundIndex?.code));
            const tooltip = {
              name: foundIndex.name,
              risks: [],
              projects: countryProjects?.length,
              topics: [],
              institutions: [],
            };
            if (countryProjects) {
              countryProjects.forEach((p) => {
                const riskIndex = tooltip?.risks?.findIndex((r) => r.label === p.risk);
                const risk = risks?.find((r) => r.value === p.risk);
                if (riskIndex !== -1) {
                  tooltip.risks[riskIndex].count += 1;
                } else {
                  tooltip.risks.push({
                    ...risk,
                    count: 1,
                  });
                }
                const instiutionIndex = tooltip?.institutions?.findIndex(
                  (r) => r === p.institution,
                );
                if (instiutionIndex === -1) {
                  tooltip.institutions.push(p.institution);
                }
                p.topics.forEach((tag) => {
                  const tagIndex = tooltip?.topics?.findIndex(
                    (r) => r === tag,
                  );
                  if (tagIndex === -1) {
                    tooltip.topics.push(tag);
                  }
                });
              });
            }
            geojsonData.features[i].tooltip = tooltip;
          }
          // if (found) {
          //   nodes[i].lat = parseFloat(found.geometry.coordinates[1]);
          //   nodes[i].lng = parseFloat(found.geometry.coordinates[0]);
          // }
        });

        const geoGenerator = d3.geoPath()
          .projection(projection);

        countryMap = map.selectAll('path')
          .data(geojsonData.features)
          .enter()
          .append('path')
          .attr('d', (d) => geoGenerator(d))
          .attr('class', (d) => (d?.tooltip ? 'country' : ''))
          .attr('id', (d) => d.properties.name_en)
          .attr('stroke-width', 1)
          .attr('stroke', '#CDCDCD')
          .attr('cursor', (d) => (d?.color ? 'pointer' : 'auto'))
          .attr('fill', (d) => (d?.color ? hexToRGBA(d.color, countryOpacity(d.size)) : 'transparent'))
          .on('click', (e, d) => {
            e.preventDefault();
            const { x, y } = e;
            if (d.tooltip) {
              setSelectedCountry({ ...d.tooltip, pos: { x, y } });
            }
          });
      } else {
        setMapTransform();
      }

      const scale = d3.scaleLinear()
        .domain([1, data.count])
        .range([2, 60]);
        // Define the force simulation
      let simulation = null;
      const groupWidth = width - 100;
      const groups = [
        {
          type: 'group',
          group: 'country',
          label: 'Country',
          x: (groupWidth / 4) + 140,
          y: (height / 4) + 50,
        },
        {
          type: 'group',
          group: 'topic',
          label: 'Tags',
          x: (groupWidth / 4) + 140,
          y: (height / 4) * 3,
        },
        {
          type: 'group',
          group: 'institution',
          label: 'Institution',
          x: ((groupWidth / 4) * 3),
          y: (height / 4) + 50,
        },
        {
          type: 'group',
          group: 'project',
          label: 'Projects',
          x: ((groupWidth / 4) * 3),
          y: (height / 4) * 3,
        },
      ];

      if (filters.view === 'groups') {
        simulation = d3.forceSimulation(nodes)
          // .force('center', d3.forceCenter(width / 2, height / 2))
          .force('x', d3.forceX().strength(0.1).x((d) => {
            const found = groups.find((gr) => gr.group === d.type);
            if (found) {
              return found.x;
            }
            return d.x;
          }))
          .force('y', d3.forceY().strength(0.1).y((d) => {
            const found = groups.find((gr) => gr.group === d.type);
            if (found) {
              return found.y;
            }
            return d.y;
          }))
          .force('charge', d3.forceManyBody().strength(-0.1))
          .force('collide', d3.forceCollide().radius((d) => {
            if (d.type === 'topic') {
              return scale(d.size) + 4;
            }
            if (d.type === 'project') {
              return 8;
            }
            if (d.type === 'institution') {
              return scale(d.size) + 5;
            }
            return scale(d.size) + 10;
          }))
          .stop();
      } else {
        simulation = d3.forceSimulation(nodes)
          .force('center', d3.forceCenter(width / 2, height / 2))
          .force('charge', d3.forceManyBody().strength(() => {
            if (!filters.isTopics) {
              return -0.2;
            }
            if (filters.view === 'map') {
              return 0.1;
            }
            return -20;
          }))
          .force('collide', d3.forceCollide((d) => {
            if (d.type === 'country' && filters.view !== 'map') {
              return (scale(d.size) + 20) * 1.2;
            }
            return scale(d.size) + 8;
          }))
          .force('link', d3.forceLink(links).id((d) => d._id))
          .stop();
      }

      function dragstarted(event, d) {
        if (!event.active) simulation.alphaTarget(0.3).restart();
        d.fx = d.x;
        d.fy = d.y;
      }

      function dragged(event, d) {
        d.fx = event.x;
        d.fy = event.y;
      }

      function dragended(event, d) {
        if (!event.active) simulation.alphaTarget(0);
        d.fx = null;
        d.fy = null;
      }
      // Create the links
      const link = g.append('g')
        .selectAll('line')
        .data(links)
        .join('line')
        .attr('id', (d) => d._id)
        .attr('stroke-width', 1)
        .attr('stroke', '#999')
        .attr('stroke-opacity', 0.4)
        .attr('opacity', () => {
          if (!filters.isLinks) {
            return 1;
          }
          return 1;
        });

      // Create the countries
      const countryNode = g.append('g')
        .attr('id', 'group-country')
        .selectAll('circle')
        .data(nodes.filter((d) => d.type === 'country'), (d) => d._id)
        .join('circle')
        .attr('id', (d) => `node-${d.index}`)
        .style('pointer-events', filters.view === 'map' ? 'none' : 'all')
        .attr('stroke', primary)
        .attr('stroke-width', 2)
        .attr('r', (d) => (scale(d.size) + 5))
        .attr('cx', width / 2)
        .attr('cy', height / 2)
        .attr('fill', white)
        .attr('opacity', 0)
        .on('mouseover', (e, d) => {
          const label = d3.select(`#label-${d.index}`);
          label.transition()
            .duration(500)
            .attr('opacity', 1);
        })
        .on('mouseout', (e, d) => {
          if (!filters.isLabels) {
            const label = d3.select(`#label-${d.index}`);
            label.transition()
              .duration(500)
              .attr('opacity', 0);
          }
        })
        .call(
          d3.drag()
            .on('start', (e, d) => dragstarted(e, d)) // start - after a new pointer becomes active (on mousedown or touchstart).
            .on('drag', (e, d) => dragged(e, d)) // drag - after an active pointer moves (on mousemove or touchmove).
            .on('end', (e, d) => dragended(e, d)), // end - after an active pointer becomes inactive (on mouseup, touchend or touchcancel).
        );
      // Create the institutions
      const institutionNode = g.append('g')
        .attr('id', 'group-institution')
        .selectAll('polygon')
        .data(nodes.filter((d) => d.type === 'institution'), (d) => d._id)
        .join('polygon')
        .attr('id', (d) => `node-${d.index}`)
        .attr('stroke', primary)
        .attr('stroke-width', 2)
        .attr('fill', white)
        .attr('opacity', 0)
        .style('pointer-events', filters.view === 'map' ? 'none' : 'all')
        .on('mouseover', (e, d) => {
          const label = d3.select(`#label-${d.index}`);
          label.transition()
            .duration(500)
            .attr('opacity', 1);
        })
        .on('mouseout', (e, d) => {
          const label = d3.select(`#label-${d.index}`);
          label.transition()
            .duration(500)
            .attr('opacity', 0);
        })
        .call(
          d3.drag()
            .on('start', (e, d) => dragstarted(e, d)) // start - after a new pointer becomes active (on mousedown or touchstart).
            .on('drag', (e, d) => dragged(e, d)) // drag - after an active pointer moves (on mousemove or touchmove).
            .on('end', (e, d) => dragended(e, d)), // end - after an active pointer becomes inactive (on mouseup, touchend or touchcancel).
        );

      const projectNode = g.append('g')
        .attr('id', 'group-project')
        .selectAll('rect')
        .data(nodes.filter((d) => d.type === 'project'), (d) => d._id)
        .join('rect')
        .attr('id', (d) => `node-${d.index}`)
        .attr('class', 'project')
        .attr('stroke', (d) => d.colors[0])
        .attr('stroke-width', 1)
        .style('pointer-events', filters.view === 'map' ? 'none' : 'all')
        .attr('rx', 2)
        .attr('width', 10)
        .attr('height', 10)
        .attr('x', width / 2)
        .attr('y', height / 2)
        .attr('opacity', 0)
        .attr('fill', (d) => d.colors[1])
        .style('cursor', 'pointer')
        .on('click', (e, d) => {
          e.preventDefault();
          const { x, y } = e;
          setSelectedProject({ ...d, pos: { x, y } });
        })
        .on('mouseover', (e, d) => {
          const label = d3.select(`#label-${d.index}`);
          label.transition()
            .duration(500)
            .attr('opacity', 1);
        })
        .on('mouseout', (e, d) => {
          const label = d3.select(`#label-${d.index}`);
          label.transition()
            .duration(500)
            .attr('opacity', 0);
        })
        .call(
          d3.drag()
            .on('start', (e, d) => dragstarted(e, d)) // start - after a new pointer becomes active (on mousedown or touchstart).
            .on('drag', (e, d) => dragged(e, d)) // drag - after an active pointer moves (on mousemove or touchmove).
            .on('end', (e, d) => dragended(e, d)), // end - after an active pointer becomes inactive (on mouseup, touchend or touchcancel).
        );

      const topicNode = g.append('g')
        .attr('id', 'group-topic')
        .selectAll('circle')
        .data(nodes.filter((d) => d.type === 'topic'), (d) => d._id)
        .join('circle')
        .attr('id', (d) => `node-${d.index}`)
        .style('pointer-events', filters.view === 'map' ? 'none' : 'all')
        .attr('r', (d) => scale(d.size))
        .attr('fill', primary)
        .attr('cx', width / 2)
        .attr('cy', height / 2)
        .attr('opacity', 0)
        .on('mouseover', (e, d) => {
          const label = d3.select(`#label-${d.index}`);
          label.transition()
            .duration(500)
            .attr('opacity', 1);
        })
        .on('mouseout', (e, d) => {
          const label = d3.select(`#label-${d.index}`);
          label.transition()
            .duration(500)
            .attr('opacity', 0);
        })
        .call(
          d3.drag()
            .on('start', (e, d) => dragstarted(e, d)) // start - after a new pointer becomes active (on mousedown or touchstart).
            .on('drag', (e, d) => dragged(e, d)) // drag - after an active pointer moves (on mousemove or touchmove).
            .on('end', (e, d) => dragended(e, d)), // end - after an active pointer becomes inactive (on mouseup, touchend or touchcancel).
        );

      const groupLabel = g.append('g')
        .style('fill', '#000')
        .selectAll('text')
        .data(groups, (d) => d.group)
        .join('text')
        .style('pointer-events', 'none')
        .attr('id', (d) => `label-${d.group}`)
        .attr('text-anchor', 'middle')
        .attr('font-weight', 'bold')
        .attr('opacity', 0)
        .attr('font-size', 18)
        .attr('fill', primary)
        .attr('x', (d) => d.x)
        .attr('y', (d) => d.y)
        .text((d) => d.label);

      const label = g.append('g')
        .style('fill', '#000')
        .selectAll('text')
        .data(nodes)
        .join('text')
        .style('pointer-events', 'none')
        .attr('id', (d) => `label-${d.index}`)
        .attr('text-anchor', 'middle')
        .attr('font-weight', 'bold')
        .attr('opacity', 0)
        .attr('x', width / 2)
        .attr('y', height / 2)
        .attr('filter', (d) => {
          if (filters.view === 'links' && d.type === 'country') {
            return null;
          }
          return 'url(#label)';
        })
        .attr('font-size', 13)
        .attr('fill', primary)
        .text((d) => d.name);

      // Update the simulation on each tick
      let transform = mapTransform || d3.zoomIdentity;

      function simulationCreateGroup() {
        const randomRadius = 50;
        function randomX(d) {
          const found = prevNodes.find((n) => n?._id === d._id);
          if (found) {
            return found.x;
          }
          const min = (width / 2) - randomRadius;
          const max = (width / 2) + randomRadius;
          return Math.floor(Math.random() * (max - min + 1)) + min;
        }
        function randomOpacity(d) {
          const found = prevNodes.find((n) => n?._id === d._id);
          if (found) {
            return 1;
          }
          return 0;
        }
        function randomY(d) {
          const min = (height / 2) - randomRadius;
          const max = (height / 2) + randomRadius;
          const found = prevNodes.find((n) => n?._id === d._id);
          if (found) {
            return found.y;
          }
          return Math.floor(Math.random() * (max - min + 1)) + min;
        }
        if (filters.view === 'map') {
          countryMap.attr('stroke-width', 1 / transform.k).transition()
            .duration(500);
        }
        if (filters.view !== 'map') {
          institutionNode
            .attr('opacity', (d) => randomOpacity(d))
            .attr('points', (d) => {
              const size = (scale(d.size) + 3);
              const x = randomX(d);
              const y = randomY(d);
              return `${x},${y - size} ${x + size},${y + size} ${x - size},${y + size}`;
            })
            .transition()
            .delay(500)
            .duration(1000)
            .attr('opacity', 1)
            .attr('points', (d) => {
              const size = (scale(d.size) + 3);
              return `${d.x},${d.y - size} ${d.x + size},${d.y + size} ${d.x - size},${d.y + size}`;
            });

          link
            .attr('opacity', 0.2)
            .attr('x1', (d) => d.source.x || 0)
            .attr('y1', (d) => d.source.y || 0)
            .attr('x2', (d) => d.source.x || 0)
            .attr('y2', (d) => d.source.y || 0)
            .transition()
            .delay(600)
            .duration(1000)
            .attr('x1', (d) => d.source.x || 0)
            .attr('y1', (d) => d.source.y || 0)
            .attr('x2', (d) => d.target.x || 0)
            .attr('y2', (d) => d.target.y || 0)
            .attr('opacity', 1);

          projectNode
            .attr('opacity', (d) => randomOpacity(d))
            .attr('x', (d) => randomX(d) - 5)
            .attr('y', (d) => randomY(d) - 5)
            .transition()
            .delay(500)
            .duration(1000)
            .attr('opacity', 1)
            .attr('x', (d) => d.x - 5 || 0)
            .attr('y', (d) => d.y - 5 || 0);

          countryNode
            .attr('opacity', (d) => randomOpacity(d))
            .attr('cx', (d) => randomX(d))
            .attr('cy', (d) => randomY(d))
            .transition()
            .delay(500)
            .duration(1000)
            .attr('opacity', 1)
            .attr('cx', (d) => d.x || 0)
            .attr('cy', (d) => d.y || 0);

          groupLabel
            .attr('opacity', 0)
            .attr('x', (d) => d.x)
            .attr('y', (gr) => {
              const group = nodes.filter((d) => d.type === gr.group).map((d) => d.y);
              const min = d3.min(group);
              const max = d3.max(group);
              let radius = ((max - min) / 2) + 30;
              if (radius < 80) {
                console.log(radius);
                radius = 80;
              }
              return gr.y - radius;
            })
            .transition()
            .delay(1000)
            .duration(1000)
            .attr('opacity', filters.view === 'groups' ? 1 : 0);

          topicNode
            .attr('opacity', (d) => randomOpacity(d))
            .attr('cx', (d) => randomX(d))
            .attr('cy', (d) => randomY(d))
            .transition()
            .delay(500)
            .duration(1000)
            .attr('opacity', 1)
            .attr('cx', (d) => d.x || 0)
            .attr('cy', (d) => d.y || 0);

          label
            .attr('x', (d) => d.x || 0)
            .attr('y', (d) => d.y + (scale(d.size) + 20) || 0)
            .attr('opacity', 0)
            .transition()
            .delay(900)
            .duration(1000)
            .attr('opacity', (d) => {
              if (filters.isLabels) {
                return 1;
              }
              if (filters.view === 'links' && d.type === 'country') {
                return 1;
              }
              return 0;
            })
            .attr('x', (d) => d.x || 0)
            .attr('y', (d) => d.y + (scale(d.size) + 20) || 0);
        }

        g.attr('transform', `translate(${transform.x},${transform.y}) scale(${transform.k})`);
        setRenderIsLoading(false);
        if (filters.view !== 'map') {
          setPrevNodes(nodes);
        } else {
          setPrevNodes([]);
        }
      }

      function simulationUpdate() {
        if (filters.view === 'map') {
          countryMap.attr('stroke-width', 1 / transform.k).transition()
            .duration(500);
        } else {
          link.attr('x1', (d) => d.source.x || 0)
            .attr('y1', (d) => d.source.y || 0)
            .attr('x2', (d) => d.target.x || 0)
            .attr('y2', (d) => d.target.y || 0);

          institutionNode
            .attr('opacity', 1)
            .attr('points', (d) => {
              const size = (scale(d.size) + 3);
              return `${d.x},${d.y - size} ${d.x + size},${d.y + size} ${d.x - size},${d.y + size}`;
            });

          projectNode
            .attr('opacity', 1)
            .attr('x', (d) => d.x - 5 || 0)
            .attr('y', (d) => d.y - 5 || 0);

          countryNode
            .attr('opacity', 1)
            .attr('cx', (d) => d.x || 0)
            .attr('cy', (d) => d.y || 0);

          topicNode
            .attr('opacity', 1)
            .attr('cx', (d) => d.x || 0)
            .attr('cy', (d) => d.y || 0);

          label
            .attr('opacity', (d) => {
              if (filters.isLabels) {
                return 1;
              }
              if (filters.view === 'links' && d.type === 'country') {
                return 1;
              }
              return 0;
            })
            .attr('x', (d) => d.x || 0)
            .attr('y', (d) => d.y + (scale(d.size) + 20) || 0);
        }

        g.attr('transform', `translate(${transform.x},${transform.y}) scale(${transform.k})`);
      }

      const zoomExtent = [0.6, 4];
      const zoom = d3.zoom()
        .scaleExtent(zoomExtent) // Limit the scale factor
        .on('zoom', (e) => {
          transform = e.transform;
          if (filters.view === 'map') {
            setMapTransform(transform);
          }
          simulationUpdate();
        });
      if (filters.view === 'groups') {
        // svg.call(zoom.transform, d3.zoomIdentity.translate(300, 100).scale(1))
        svg.call(zoom);
        for (
          let i = 0,
            n = 500;
          i < n;
          i += 1) {
          simulation.tick();
        }
        simulation.restart();
        window.requestAnimationFrame(simulationCreateGroup);
      } else {
        svg.call(zoom);
        for (
          let i = 0,
            n = 500;
          i < n;
          i += 1) {
          simulation.tick();
        }
        simulation.restart();
        window.requestAnimationFrame(simulationCreateGroup);
      }
    }
    return () => {
      svg.selectAll('g').remove();
    };
  }, [data?.nodes, width]);

  return (
    <div
      className={styles.container}
    >
      {/* <button
        type="button"
        onClick={() => exportD3ToPDF()}
        className={styles.export}
      >
        Export
      </button> */}
      {width && (
        <ProjectInfos
          project={selectedProject}
          close={() => setSelectedProject()}
        />
      )}
      {width && (
        <CountryInfos
          country={selectedCountry}
          close={() => setSelectedCountry()}
        />
      )}
      {renderIsLoading
        && (
        <div className={styles.loader}>
          <Oval
            height={40}
            width={40}
            color="#00005C"
            wrapperStyle={{}}
            wrapperClass=""
            visible
            ariaLabel="oval-loading"
            secondaryColor="#8e8eed"
            strokeWidth={6}
            strokeWidthSecondary={6}
          />
        </div>
        )}
      <div id="svg">
        <svg
          ref={svgRef}
          width={width}
          height={height}
          style={{ opacity: renderIsLoading ? 0.4 : 1 }}
        >
          <defs>
            <filter x="0" y="0" width="1" height="1" id="label">
              <feFlood floodColor="rgba(243, 241, 235, 0.8)" result="bg" />
              <feMerge>
                <feMergeNode in="bg" />
                <feMergeNode in="SourceGraphic" />
              </feMerge>
            </filter>
          </defs>
        </svg>
      </div>
    </div>
  );
}
