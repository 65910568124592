/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/prop-types */
import React, { useState } from 'react';

import CheckIcon from '../CheckIcon';
import arrow from '../../assets/images/icons/arrow.svg';
import country from '../../assets/images/icons/country.svg';
import topic from '../../assets/images/icons/topic.svg';
import institution from '../../assets/images/icons/institution.svg';
import project from '../../assets/images/icons/project.svg';
import styles from './DropDown.module.scss';

const icons = {
  Countries: country,
  Tags: topic,
  Institutions: institution,
  Projects: project,
};

export default function DropDown({
  label,
  values,
  handleChange,
  options,
  isActive,
  setIsActive,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [search, setSearch] = useState('');
  const height = label === 'Risks' ? 150 : 210;
  const filteredOptions = options
    ?.filter((opt) => {
      if (!search) {
        return opt;
      }
      if (opt?.label?.toLowerCase()?.includes(search.toLowerCase())) {
        return opt;
      }
      return null;
    });

  function onChange(value) {
    let selected = [...values];
    if (values.find((v) => v === value)) {
      selected = values.filter((v) => v !== value);
    } else {
      selected.push(value);
    }
    handleChange(selected);
  }

  return (
    <div className={styles.container} onMouseLeave={isOpen ? () => setIsOpen(false) : null}>
      <div className={styles.header}>
        <button
          type="button"
          className={styles.check}
          onClick={() => setIsActive(!isActive)}
        >
          <CheckIcon checked={isActive} />
        </button>
        {icons[label] && <img src={icons[label]} alt={label} />}
        <p onClick={() => setIsOpen(!isOpen)}>{label}</p>
        {values?.length > 0
          && (
          <div className={`${styles.count}`}>
            {values?.length}
          </div>
          )}
        {options
        && (
        <button
          type="button"
          className={`${styles.toggle} ${isOpen ? styles.open : ''}`}
          onClick={() => setIsOpen(!isOpen)}
        >
          <img src={arrow} alt="toggle" />
        </button>
        )}
      </div>
      {options
        && (
        <div
          className={styles.dropdown}
          style={{ height: isOpen ? height : 0 }}
        >
          <input
            type="text"
            value={search}
            placeholder="Your research"
            onChange={(e) => setSearch(e.target.value)}
          />
          <div
            className={styles.options}
          >
            {options?.filter((o) => values.find((v) => v === o.value))
              ?.map((opt) => (
                <button
                  type="button"
                  key={opt.label}
                  onClick={() => onChange(opt.value)}
                >
                  <CheckIcon checked />
                  {' '}
                  <p>{opt.label}</p>
                </button>
              ))}
            {filteredOptions?.filter((o) => !values.find((v) => v === o.value))
              ?.map((opt) => (
                <button
                  type="button"
                  key={opt.label}
                  onClick={() => onChange(opt.value)}
                >
                  <CheckIcon checked={values.length === 0} />
                  {' '}
                  <p>{opt.label}</p>
                </button>
              ))}
          </div>
        </div>
        )}
    </div>
  );
}
