/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/prop-types */
import React, { useState, useRef, useEffect } from 'react';
import RangeSlider from 'react-range-slider-input';
import 'react-range-slider-input/dist/style.css';
import { useSelector } from 'react-redux';
import './slider.scss';
import styles from './timeline.module.scss';

function Label({
  option, isActive, index, values, handleChange,
}) {
  const { data } = useSelector((state) => state.projectsReducer);
  const projectsByYear = data?.projectsByYear || [];
  const max = projectsByYear && Math.max(...projectsByYear.map((y) => y.count));
  const count = projectsByYear?.find((y) => y.label === option.label)?.count;

  const barWidth = () => {
    if (max > 0 && !count) return `${0}%`;
    const calc = (count * 100) / max;
    if (calc > 0) { return `${calc}%`; }
    return '0%';
  };

  const [end, start] = values;

  function handleChangeValue() {
    const selected = [...values];
    if (index > start) {
      selected[1] = index;
    }
    if (index < end) {
      selected[0] = index;
    }
    if (isActive && index > end && index <= start) {
      selected[0] = index;
      return handleChange(selected);
    }
    if (isActive && index < start) {
      selected[1] = index;
    }
    return handleChange(selected);
  }

  return (
    <button
      type="button"
      className={`${styles.label} ${isActive ? styles.active : ''} ${values.find((v) => v === index) ? styles.select : ''}`}
      onClick={() => handleChangeValue()}
    >
      <div className={styles.bar}>
        <div className={styles.value} style={{ width: barWidth() }} />
      </div>
      <div className={styles.puce} />
      <p>{option.label}</p>
    </button>
  );
}

export default function Timeline({
  options, handleChange,
}) {
  const [value, setValue] = useState([0, options.length - 1]);
  const timerRef = useRef(null);
  const { filters } = useSelector((state) => state.projectsReducer);
  function handleChangeDate(values) {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
    const [end, start] = values;
    if (values?.length) {
      const { startDate } = options[start];
      const endDate = options[end]?.endDate;
      timerRef.current = setTimeout(() => {
        handleChange({
          startDate,
          endDate,
        });
      }, 1000);

      setValue(values);
    }
  }

  useEffect(() => {
    if (filters?.startDate) {
      const startIndex = options.findIndex((opt) => opt.startDate === filters?.startDate) || 0;
      const endIndex = options.findIndex((opt) => opt.endDate === filters?.endDate) || 0;
      setValue([endIndex, startIndex]);
    }
  }, []);

  return (
    <div className={styles.timeline}>
      <div className={styles.slider}>
        <RangeSlider
          orientation="vertical"
          min={0}
          value={value}
          max={options.length - 1}
          onInput={(val) => handleChangeDate(val)}
        />
        <div className={styles.labels}>
          {options.map((opt, i) => (
            <Label
              key={opt.label}
              option={opt}
              isActive={i >= value[0] && i <= value[1]}
              values={value}
              index={i}
              handleChange={(val) => handleChangeDate(val)}
            />
          ))}
        </div>
      </div>
    </div>
  );
}
