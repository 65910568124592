/* eslint-disable default-param-last */
import {
  POST_NODES_FILTERED,
  PROJECT_IS_LOADING,
  PROJECT_ERROR,
  SET_FILTERS,
  GET_LISTS,
} from '../actions/types';

export const DEFAULT_STATE = {
  lists: {},
  isLoading: [],
  error: null,
  data: null,
  filters: {
    view: 'groups',
    startDate: null,
    endDate: null,
    isCountries: true,
    isInstitutions: true,
    isProjects: true,
    isTopics: true,
    filters: [],
    countries: [],
    institutions: [],
    topics: [],
    risks: [],
  },
};

const projectsReducer = (state = DEFAULT_STATE, action) => {
  let updatedState = {};
  switch (action.type) {
    case POST_NODES_FILTERED: // SET ALL INDICATORS
      updatedState = {
        ...state,
        data: {
          ...state.data,
          ...action.payload,
        },
        isLoading: [...state.isLoading.filter((l) => l !== action.type)],
        error: null,
      };
      break;
    case PROJECT_IS_LOADING:
      updatedState = { ...state, isLoading: [...state.isLoading, action.payload] };
      break;
    case GET_LISTS:
      updatedState = { ...state, lists: action.payload };
      break;
    case SET_FILTERS:
      updatedState = { ...state, filters: action.payload };
      break;
    case PROJECT_ERROR:
      updatedState = { ...state, error: action.payload };
      break;
    default:
      updatedState = { ...state };
      break;
  }
  return updatedState;
};

export default projectsReducer;
