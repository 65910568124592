/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import useOutsideClick from '../../hooks/useClickOutside';
import useWindowSize from '../../hooks/useWindowSize';
import styles from './ProjectInfos.module.scss';

function ProjectInfos({ project, close }) {
  const { width, height } = useWindowSize();
  const [topics, setTopics] = useState([]);

  const date = project?.startDate && new Date(project.startDate).getFullYear();
  const [prevPos, setPrevPos] = useState({
    x: width / 2,
    y: height / 2,
  });
  function handleClose(e) {
    if (e.target.className.baseVal !== 'project') {
      close();
    }
  }

  useEffect(() => {
    if (project?.pos) {
      setTopics([...project.topics]);
      setPrevPos({
        x: project.pos.x + 25,
        y: project.pos.y - 150,
      });
    }
  }, [project]);

  const projectRef = useOutsideClick(handleClose);
  return (
    <div
      ref={projectRef}
      className={styles.container}
      style={{
        opacity: project ? 1 : 0,
        top: prevPos?.y || 0,
        left: prevPos?.x || 0,
        backgroundColor: project?.colors[1] || 'transparent',
      }}
    >
      <div className={styles.content}>
        {topics.length > 0
        && (
          <div className={styles.topics}>
            {topics?.map((t) => <p key={`${t}-${project?._id}-topic`} style={{ backgroundColor: project?.colors[0] || 'transparent' }}>{t}</p>)}
          </div>
        )}
        <h2>
          {project?.name}
        </h2>
        <div className={styles.infos}>
          <p>
            {project?.institution}
            <br />
            {project?.country?.name}
          </p>
          <p>
            {date}
          </p>
        </div>
        <p className={styles.candidate}>
          {project?.candidate?.firstName}
          {' '}
          {project?.candidate?.lastName}
        </p>
      </div>
    </div>
  );
}

export default ProjectInfos;
