import socio from '../assets/images/risks/Socio-economics.svg';
import tech from '../assets/images/risks/New-Tech.svg';
import env from '../assets/images/risks/Environment.svg';
import health from '../assets/images/risks/Health.svg';

const risks = [
  {
    value: 'Socio-economics',
    label: 'Socio-economics',
    icon: socio,
    colors: [
      '#BD3C3A',
      '#F3A1A0',
    ],
    group: 'risk',
  },
  {
    value: 'New Tech',
    label: 'New Tech',
    icon: tech,
    colors: [
      '#9523EE',
      '#CB8EFB',
    ],
    group: 'risk',
  },
  {
    value: 'Environment',
    label: 'Environment',
    icon: env,
    colors: [
      '#679179',
      '#A0D9B4',
    ],
    group: 'risk',
  },
  {
    value: 'Health',
    label: 'Health',
    icon: health,
    colors: [
      '#24788A',
      '#7FCBDB',
    ],
    group: 'risk',
  },
];

export default risks;
